
























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SelectDepot extends Vue {
  isBusy = true;
  depots = [];
  fields = [
    { key: 'sn', label: this.$i18n.translate('Shortname') },
    { key: 'na', label: this.$i18n.translate('Name') },
  ];

  async mounted() {
    await this.$nextTick();
    const json = await this.$store.state.hyrma.client._client.get('/Hyrma/Depot/Edit_DepotList_SlickGrid?sortAsc=true&sortBy=na');
    this.depots = json.data.data;
    this.isBusy = false;
  }

  async onSelect(item: any) {
    this.$router.push(`/RentalObjectStocktaking/${this.$route.params.id}/SelectInventoryType?depot=${item[0].id}`);
  }
}
